import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-come-si-gioca',
  templateUrl: './come-si-gioca.component.html',
  styleUrls: ['./come-si-gioca.component.css']
})
export class ComeSiGiocaComponent implements OnInit {
  item: any;
  traduzioni: any;

  constructor(
    private router: Router,
    public mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.mainService.getContent(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;
        this.item = item;
        this.mainService.showLoader(false);
    });
  }

}
