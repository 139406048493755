<ng-container *ngIf="myForm">
  <div class="single-port-title pb-20">
    <h3>{{traduzioni.recuperoPassword}}</h3>
  </div>
  <div class="row pb-20">
    <div class="col-xl-8">
      <form>
        <div class="form-group pb-20" [formGroup]="myForm">
          <label for="" [innerHTML]="traduzioni.istruzioniRecuperoPassword"></label>
          <input [class.is-invalid]="objInputEmail.invalid && objInputEmail.touched"  type="email" class="form-control" formControlName="email" aria-describedby="">
          <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.required">
            Campo <strong>OBBLIGATORIO</strong>
          </small>
          <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.pattern">
              Valore <b>NON VALIDO</b>
          </small>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-8">
      <div class="single-shortcode-btn black-button medium-button mb-30">
        <a class="btn-hover pointer" (click)="recover()" [class.spinner]="loading" [disabled]="loading" >{{traduzioni.recupera}}</a>
      </div>
    </div>
  </div>
</ng-container>
