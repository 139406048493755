import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CappelloVoltiComponent } from './cappello-volti/cappello-volti.component';
import { DettaglioVoltiComponent } from './dettaglio-volti/dettaglio-volti.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { BottomMediaModule } from 'src/app/modules/bottom-media/bottom-media.module';
import { SliderVoltiComponent } from './slider-volti/slider-volti.component';
import { VideogalleryVoltiComponent } from './videogallery-volti/videogallery-volti.component';


@NgModule({
  declarations: [CappelloVoltiComponent, DettaglioVoltiComponent, SliderVoltiComponent, VideogalleryVoltiComponent],
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule,
    BottomMediaModule
  ],
  exports: [
    SliderVoltiComponent
  ]
})
export class VoltiDellaCorsaModule { }
