import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElencoSaleComponent } from './elenco-sale/elenco-sale.component';
import { RouterModule } from '@angular/router';
import { DettaglioSalaComponent } from './dettaglio-sala/dettaglio-sala.component';
import { ArgomentoSalaComponent } from './argomento-sala/argomento-sala.component';
import { BottomMediaModule } from 'src/app/modules/bottom-media/bottom-media.module';
import { VideogalleryArgomentoComponent } from './videogallery-argomento/videogallery-argomento.component';



@NgModule({
  declarations: [
    ElencoSaleComponent,
    DettaglioSalaComponent,
    ArgomentoSalaComponent,
    VideogalleryArgomentoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BottomMediaModule
  ]
})
export class SaleModule { }
