import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as bootstrap from "bootstrap";
import * as $ from 'jquery';
import 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderModule } from './modules/header/header.module';
import { FooterModule } from './modules/footer/footer.module';
import { LoaderModule } from './modules/loader/loader.module';
import { HomePageModule } from './pages/home-page/home-page.module';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { MuseoMultimedialeModule } from './pages/museo-multimediale/museo-multimediale.module';
import { TestatinaModule } from './modules/testatina/testatina.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginaGenericaModule } from './pages/pagina-generica/pagina-generica.module';
import { SaleModule } from './pages/sale/sale.module';
import { IlMedioevoModule } from './pages/il-medioevo/il-medioevo.module';
import { VoltiDellaCorsaModule } from './pages/volti-della-corsa/volti-della-corsa.module';
import { RouterModule } from '@angular/router';
import { MTicketMShopModule } from './pages/m-ticket-m-shop/m-ticket-m-shop.module';
import { PaginaCortesiaModule } from './pages/pagina-cortesia/pagina-cortesia.module';
import { SafePipe } from './services/safe.pipe';
import { MultimediaModule } from './pages/multimedia/multimedia.module';
import { MGameModule } from './pages/m-game/m-game.module';
import { ReservedAccessModule } from './modules/reserved-access/reserved-access.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    LoaderModule,
    HomePageModule,
    PaginaGenericaModule,
    MuseoMultimedialeModule,
    SaleModule,
    TestatinaModule,
    IlMedioevoModule,
    VoltiDellaCorsaModule,
    MTicketMShopModule,
    PaginaCortesiaModule,
    MultimediaModule,
    MGameModule,
    NgbModule,
    ReservedAccessModule,
  ],
  exports: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
