<div class="single-portfolio-area pt-80 pb-60" *ngIf="item">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                  <div class="single-portfolio-img zoom-hover mb-50" *ngIf="item.immagine">
                      <img src="{{item.immagine}}" alt="">
                  </div>
                  <div class="portfolio-decription-wrap">
                      <div class="row">
                          <div class="col-12">
                              <div class="single-port-peragraph" [innerHTML]="item.testo">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
