import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { SaleService } from '../sale.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-elenco-sale',
  templateUrl: './elenco-sale.component.html',
  styleUrls: ['./elenco-sale.component.css']
})
export class ElencoSaleComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;

  constructor(
    private service: SaleService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getElenco(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([items, traduzioni]) => {
        this.traduzioni = traduzioni;

        for(let i=0; i < items.length; i++) {
          items[i].routerLink = '/' + this.mainService.language + '/10/' + this.traduzioni.sezioni[10].titoloUrl + '/' + items[i].id + '/' + items[i].titoloUrl;
        }
        this.arrayItems = items;
        this.mainService.showLoader(false);
     });
  }

}
