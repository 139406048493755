<app-slider *ngIf="homePage?.slider"
  [items]="homePage.slider"
></app-slider>

<app-slider-medioevo *ngIf="homePage?.medioevo"
  [items]="homePage.medioevo"
></app-slider-medioevo>

<div class="pb-190">
  <app-slider-volti></app-slider-volti>
</div>

<div class="works-area pb-190 pb-100-r home-multimedia" *ngIf="homePage?.multimedia && (homePage?.multimedia.videoCode != '')">
  <div class="container">
      <div class="row d-flex align-items-center">
          <div class="col-lg-12 col-md-12">
              <div class="tools-content text-center mb-20">
                  <h2>{{homePage?.multimedia.titolo}}</h2>
                  <p [innerHTML]="homePage?.multimedia.testo"></p>
              </div>
          </div>
          <div class="col-lg-12 col-md-12">
              <iframe width="100%" height="600" [src]="homePage.multimedia.videoUrl"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
  </div>
</div>

<div class="best-area pb-120 home-sale" *ngIf="homePage?.sale.length > 0">
  <div class="container">
      <div class="section-title text-center mb-78">
          <h2>{{traduzioni?.sezioni[10].denominazione}}</h2>
      </div>
      <div class="row d-flex align-items-center">
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="best-content best-content-left">
                  <div class="single-best-wrap">
                      <div class="single-best-text">
                          <a [routerLink]="homePage.sale[0].routerLink">
                              <h5>{{homePage.sale[0].titolo}}</h5>
                          </a>
                      </div>
                      <div class="single-best-icon">
                          <i class="ti-crown"></i>
                      </div>
                  </div>
                  <div class="single-best-wrap">
                      <div class="single-best-text">
                          <a [routerLink]="homePage.sale[2].routerLink">
                              <h5>{{homePage.sale[2].titolo}}</h5>
                          </a>
                      </div>
                      <div class="single-best-icon">
                          <i class="ti-flag-alt"></i>
                      </div>
                  </div>
                  <div class="single-best-wrap">
                      <div class="single-best-text">
                        <a [routerLink]="homePage.sale[4].routerLink">
                          <h5>{{homePage.sale[4].titolo}}</h5>
                        </a>
                      </div>
                      <div class="single-best-icon">
                          <i class="ti-shield"></i>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="best-img ml-20 mr-20">
                  <img alt="" src="assets/img/logo-verticale.png">
              </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="best-content best-content-right">
                  <div class="single-best-wrap">
                      <div class="single-best-icon">
                          <i class="ti-hummer"></i>
                      </div>
                      <div class="single-best-text">
                          <a [routerLink]="homePage.sale[1].routerLink">
                              <h5>{{homePage.sale[1].titolo}}</h5>
                          </a>
                      </div>
                  </div>
                  <div class="single-best-wrap">
                      <div class="single-best-icon">
                          <i class="ti-control-record"></i>
                      </div>
                      <div class="single-best-text">
                          <a [routerLink]="homePage.sale[3].routerLink">
                              <h5>{{homePage.sale[3].titolo}}</h5>
                          </a>
                      </div>
                  </div>
                  <div class="single-best-wrap">
                      <div class="single-best-icon">
                          <i class="ti-star"></i>
                      </div>
                      <div class="single-best-text">
                          <a class="" [routerLink]="'/' + mainService.language + '/24/' + traduzioni.sezioni[24].titoloUrl">
                              <h5>{{traduzioni?.sezioni[24].denominazione}}</h5>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
