<div class="row pt-30 pb-30">
  <div *ngIf="mostraEsito; else mostraDomanda">
    <h4 class="pb-40 testoEsito"> {{testoEsito}} </h4>
    <div class="iconaEsito">
      <img [src]="iconaEsito">
    </div>
    <div class="row pt-60 pb-60" style="text-align: center;">
      <div class="single-shortcode-btn black-button medium-button mb-30">
        <a class="btn-hover" [routerLink]="['/' + mainService.language + '/30/' + traduzioni.sezioni[30].titoloUrl]">
          {{traduzioni.mostraRisultato}}
        </a>
      </div>
    </div>
  </div>
  <ng-template #mostraDomanda>
    <div *ngIf="myDomanda">
      <h4 class="pb-20"> {{myDomanda.domanda}} </h4>
      <div class="pb-20">
        <a class="pointer" (click)="clickRisposta(1)"><div id="risposta1" class="risposte risposteAll"> {{myDomanda.risposta_1}} </div></a>
      </div>
      <div class="pb-20">
        <a class="pointer" (click)="clickRisposta(2)"><div id="risposta2" class="risposte risposteAll"> {{myDomanda.risposta_2}} </div></a>
      </div>
      <div class="">
        <a class="pointer" (click)="clickRisposta(3)"><div id="risposta3" class="risposte risposteAll"> {{myDomanda.risposta_3}} </div></a>
      </div>
    </div>
  </ng-template>
</div>
