<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let slide of items; let i = index">
    <ng-template carouselSlide>
      <div class="single-slider slider-overly bg-img pt-50 height-100vh d-flex align-items-center" data-dot="{{i+1}}" style="background-image: url({{slide.immagine}});">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="slider-text slider-animated-1 text-center">
                        <img class="logo-slide" alt="" src="/assets/img/disegno-logo.png">
                        <h1 class="animated" [ngStyle]="{'color':slide.coloreTesto}">{{slide.titolo}}</h1>
                        <h5 class="animated" [ngStyle]="{'color':slide.coloreTesto}">
                          {{slide.sottotitolo}}
                        </h5>
                        <div class="slider-btn mt-30" *ngIf="slide.link">
                            <a class="sub-slider-btn-1 animated mr-8 btn-hover" target="_blank" href="{{slide.link}}">{{slide.titololink}}</a>
                        </div>
                        <div class="slider-btn mt-30" *ngIf="slide.routerlink">
                          <a class="sub-slider-btn-1 animated mr-8 btn-hover" [routerLink]='slide.routerlink'>{{slide.titololink}}</a>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
<div class="slider-icon">
  <i class="ti-mouse"></i>
</div>
