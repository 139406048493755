import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MainServiceService } from 'src/app/services/main-service.service';

@Component({
  selector: 'app-slider-medioevo',
  templateUrl: './slider-medioevo.component.html',
  styleUrls: ['./slider-medioevo.component.css']
})
export class SliderMedioevoComponent implements OnInit {
  @Input() items: any[];
  traduzioni: any;

  customOptions: OwlOptions = {};

  constructor(
    public mainService: MainServiceService
  ) {
    this.customOptions = {
      loop: true,
      nav: true,
      autoplay: false,
      autoplayTimeout: 5000,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
      dots: false,
      responsive: {
          0: {
              items: 1
          },
          768: {
              items: 1
          },
          1000: {
              items: 1
          }
      }
    }
  }

  ngOnInit(): void {
    this.mainService.getTraductionsSubject().subscribe(data => {
      if (data !== null) {
        this.traduzioni = data;
      }
    });

  }

}
