import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComeSiGiocaComponent } from './come-si-gioca/come-si-gioca.component';
import { ElencoArgomentiComponent } from './elenco-argomenti/elenco-argomenti.component';
import { RouterModule } from '@angular/router';
import { ReservedAccessModule } from 'src/app/modules/reserved-access/reserved-access.module';
import { GiocaComponent } from './gioca/gioca.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { ScanComponent } from './scan/scan.component';
import { DomandaComponent } from './domanda/domanda.component';



@NgModule({
  declarations: [ComeSiGiocaComponent, ElencoArgomentiComponent, GiocaComponent, ScanComponent, DomandaComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReservedAccessModule,
    NgxScannerQrcodeModule
  ],
  exports: [
    ComeSiGiocaComponent,
    GiocaComponent
  ]
})
export class MGameModule { }
