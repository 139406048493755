import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { VoltiDellaCorsaService } from '../volti-della-corsa.service';
import { MainServiceService } from 'src/app/services/main-service.service';


@Component({
  selector: 'app-videogallery-volti',
  templateUrl: './../../multimedia/galleria-video/galleria-video.component.html',
  styleUrls: ['./../../multimedia/galleria-video/galleria-video.component.css']
})
export class VideogalleryVoltiComponent implements OnInit {
  arrayItems: any[];
  traduzioni: any;

  constructor(
    private service: VoltiDellaCorsaService,
    public mainService: MainServiceService,
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.service.getElencoVideo(this.mainService.detailId),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([items, traduzioni]) => {
        this.traduzioni = traduzioni;
        for(let i=0; i < items.length; i++) {
          items[i].videoUrl = this.mainService.sanitizeURL('https://www.youtube.com/embed/' + items[i].videoCode);
        }
        this.arrayItems = items;
        this.mainService.showLoader(false);
     });
  }

}
