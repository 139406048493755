<div class="single-portfolio-area pt-80" *ngIf="item">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                  <div class="portfolio-decription-wrap">
                      <div class="row">
                          <div class="col-12">
                              <div class="single-port-peragraph mb-30" [innerHTML]="item.testo"  >

                              </div>
                              <div class="row" *ngIf="item.immagini.length > 0">
                                  <div class="col-lg-4 col-md-4" *ngFor="let immagine of item.immagini">
                                      <div class="single-portfolio-img zoom-hover mb-30">
                                          <img src="{{immagine}}" alt="">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
