import { Component, OnInit, Input } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MGameService } from '../m-game.service';
import { NotificationsService } from 'src/app/services/notification.service';

declare var require: any;

@Component({
  selector: 'app-domanda',
  templateUrl: './domanda.component.html',
  styleUrls: ['./domanda.component.css']
})
export class DomandaComponent implements OnInit {
  @Input() myItem: any;
  traduzioni: any;
  myDomanda: any;
  mostraEsito: boolean = false;
  testoEsito: string = "";
  iconaEsito: string = "";

  constructor(
    private service: MGameService,
    private router: Router,
    public mainService: MainServiceService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.service.getDomanda(this.myItem.id, this.myItem.id_sala),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;

        if (item.risposto == "1") {
          if (item.rispostaEsatta == "1") {
            this.testoEsito = this.traduzioni.giaRispostoCORRETTA;
            this.iconaEsito = "assets/img/rispostoOK.png"
          }
          else {
            this.testoEsito = this.traduzioni.giaRispostoERRATA;
            this.iconaEsito = "assets/img/rispostoKO.png"
          }
          this.mostraEsito = true;
        }
        else {
          this.mostraEsito = false;
          this.myDomanda = item;
        }
        this.mainService.showLoader(false);
      });
  }


  clickRisposta(numRisposta) {
    $('.risposteAll').removeClass('risposte-check');
    $('.risposteAll').addClass('risposte');

    if (numRisposta !== 0) {
      $('#risposta' + numRisposta).addClass('risposte-check');

      const testoRisposta = $('#risposta' + numRisposta).html();
      const Swal = require('sweetalert2');

      Swal.fire({
        title: this.traduzioni.confermiRisposta + '?',
        icon: 'question',
        // tslint:disable-next-line: max-line-length
        html: this.traduzioni.selezionatoRisposta + ' "' + testoRisposta + '"<br>' + this.traduzioni.premiConfermaAnnulla + '.',
        showCancelButton: true,
        confirmButtonText: this.traduzioni.conferma,
        cancelButtonText: this.traduzioni.annulla,
        buttonsStyling: true
      }).then((result) => {
        if (result.value) {
          this.service.sendRisposta(this.myItem.id, this.myItem.id_sala, numRisposta)
          .pipe(first())
          .subscribe(
            data => {
              if (data.esatta == "1") {
                this.testoEsito = this.traduzioni.complimentiRispostaCORRETTA;
                this.iconaEsito = "assets/img/rispostoOK.png"
              }
              else {
                this.testoEsito = this.traduzioni.dispiaceRispostaERRATA;
                this.iconaEsito = "assets/img/rispostoKO.png"
              }
              this.mostraEsito = true;
            },
            error => {
              this.notificationsService.show('error', '', error.error.message);
              this.clickRisposta(0);
            }
          );
        } else {
          this.clickRisposta(0);
        }
      });
    }
  }

}
