<div class="tools-area pt-190 pb-190 ptmobile-50 home-medioevo">
<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let item of items; let i = index">
    <ng-template carouselSlide>
        <div class="container">
            <div class="tools-slider-active owl-carousel">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-8 col-md-6">
                        <div class="tools-img">
                            <img src="{{item.immagine}}" alt="">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="tools-content">
                            <h2>{{item.titolo}}</h2>
                            <p [innerHTML]="item.testo"></p>
                            <div class="tools-btn mt-45">
                                <a class="btn-hover default-btn" [owlRouterLink]="'/' + mainService.language + '/15/' + traduzioni?.sezioni[15].titoloUrl">{{traduzioni?.leggi_tutto}}</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
</div>
