<footer class="footer-area theme-bg pt-50 pb-20 pbmobile-0" *ngIf="configuration?.footer">
  <div class="container">
    <div class="footer-middle pt-65 pb-50 ptmobile-0">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget mb-40 footer-logo">
            <a [routerLink]="['/']">
              <img class="logo-normally-none logo-f" alt="" src="assets/img/logo-footer.png">
            </a>
            <img class="logo-normally-none logo-f loghi-bottom" alt="" src="assets/img/loghetti-footer.png">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="footer-widget mb-40 footer-info">
          <div class="footer-title mb-20">
              <h4>{{traduzioni?.contattaci}}</h4>
            </div>
            <div class="footer-cont-info">
              <div class="single-footer-cont-info" *ngIf="configuration.footer.telefono">
                <div class="cont-info-icon">
                  <i class="ti-mobile"></i>
                </div>
                <div class="cont-info-content">
                  <p><a href="tel: {{configuration.footer.telefono}}" target="_blank" >{{configuration.footer.telefono}}</a></p>
                </div>
              </div>
              <div class="single-footer-cont-info" *ngIf="configuration.footer.email">
                <div class="cont-info-icon">
                  <i class="ti-email"></i>
                </div>
                <div class="cont-info-content">
                  <p><a href="mailto: {{configuration.footer.email}}" target="_blank" >{{configuration.footer.email}}</a></p>
                </div>
              </div>
              <div class="single-footer-cont-info" *ngIf="configuration.footer.indirizzo">
                <div class="cont-info-icon">
                  <i class="ti-location-pin"></i>
                </div>
                <div class="cont-info-content">
                  <p>{{configuration.footer.indirizzo}}</p>
                </div>
              </div>
            </div>
            <div class="footer-social" *ngIf="configuration?.social.length > 0">
              <ul>
                <li *ngFor="let social of configuration.social">
                  <a href="{{social.url}}" target="_blank" ><i class="ti-{{social.iconAlt}}"></i></a>
                </li>
              </ul>
            </div>
        </div>
      </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget mb-40">
            <div class="footer-title mb-20">
              <h4>{{traduzioni?.informazioni}}</h4>
            </div>
            <div class="footer-cont-info">
              <div class="single-footer-cont-info">
                <div class="cont-info-content">
                  <a [routerLink]="['/' + mainService.language + '/33/' + traduzioni?.sezioni[33].titoloUrl]">
                    {{traduzioni?.sezioni[33].denominazione}}
                  </a>
                </div>
              </div>
              <div class="single-footer-cont-info">
                <div class="cont-info-content">
                  <a [routerLink]="['/' + mainService.language + '/24/' + traduzioni?.sezioni[24].titoloUrl]">
                    {{traduzioni?.sezioni[24].denominazione}}
                  </a>
                </div>
              </div>
<!--
              <div class="single-footer-cont-info">
                <div class="cont-info-content">
                  <a [routerLink]="['/' + mainService.language + '/29/' + traduzioni?.sezioni[29].titoloUrl]">
                    {{traduzioni?.sezioni[29].denominazione}}
                  </a>
                </div>
              </div>
-->
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget mb-40">
            <div class="footer-title mb-20">
              <h4>Photo</h4>
            </div>
            <div class="instagram-img" *ngIf="configuration?.footer.immaginiCrop.length">
              <ul>
                <li *ngFor="let image of configuration?.footer.immaginiCrop">
                  <img src="{{image}}" alt="">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom pt-25">
      <div class="row">
        <div class="col-12">
          <div class="copyright text-center">
            <p>Copyright {{year}} © Museo MultiMedievale Corsa all'Anello | All Right Reserved.</p>
            <p>
              <ng-container *ngFor="let secondaryMenuItem of mainService.traductions?.menuSecondario; first as isFirst">
                <ng-container *ngIf="!isFirst">
                  <span> | </span>
                </ng-container>
                <ng-container *ngIf="secondaryMenuItem.url; else noExternalUrl">
                    <a [href]="secondaryMenuItem.url" target="_blank" rel="noopener" class="text-decoration-none text-nowrap">{{secondaryMenuItem.denominazione}}</a>
                </ng-container>
                <ng-template #noExternalUrl>
                  <ng-container *ngIf="secondaryMenuItem.id != 97; else isCookiePolicy">
                    <a [routerLink]="'/' + mainService.language + '/' + secondaryMenuItem.id + '/' + secondaryMenuItem.titoloUrl" class="text-decoration-none text-nowrap">{{secondaryMenuItem.denominazione}}</a>
                  </ng-container>
                  <ng-template #isCookiePolicy>
                    <a href="javascript:void(0)" onclick="cookieconsent.openPreferencesCenter()" class="text-decoration-none text-nowrap">{{secondaryMenuItem.denominazione}}</a>
                  </ng-template>
                </ng-template>
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
