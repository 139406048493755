import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';
import { NotificationsService } from 'src/app/services/notification.service';
import { ReservedAccessService } from '../reserved-access.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  traduzioni: any;
  myForm: FormGroup;
  loading: boolean;

  @Output() public onSuccess = new EventEmitter();

  constructor(
    private mainService: MainServiceService,
    private formBuilder: FormBuilder,
    private service: ReservedAccessService,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {
    forkJoin([
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([traduzioni]) => {
        this.traduzioni = traduzioni;
        this.myForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.pattern(this.mainService.emailPattern)]],
          password: ['', [Validators.required]]
       });
    });
  }

  get objInputEmail() {
    return this.myForm.get('email');
  }
  get objInputPassword() {
    return this.myForm.get('password');
  }

  signIn() {
    if (!this.myForm.valid) {
      this.mainService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.service.login(this.myForm.value.email, this.myForm.value.password)
    .pipe(first())
    .subscribe(
      data => {
        this.service.saveUserToken(data.jwt);
        this.service.setUserBasicData(data.user);
        this.onSuccess.emit();
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.loading = false;
      }
    );
  }

}
