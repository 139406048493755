<div class="pb-30">
  <div class="single-shortcode-btn black-button medium-button mb-30" *ngIf="!action.isStart">
    <a class="btn-hover" (click)="turnOn(action)">
      Avvia la scansione
    </a>
  </div>

  <div class="single-shortcode-btn black-button medium-button mb-30" *ngIf="action.isStart">
    <a class="btn-hover" (click)="turnOff(action)">
      Ferma la scansione
    </a>
  </div>

  <p>Elenco camere trovate: {{camere | json}}</p>
  <p>02- Cambio camera in: {{nuovaCamera | json}}</p>

  <p *ngIf="action.isLoading">⌛ Caricamento...</p>

  <div [style.display]="action.isStart ? 'block' : 'none'" class="row">
    <div class="col-md-6 offset-md-3 col-12">
      <ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>
    </div>

    <div *ngIf="numDevices > 1" style="width: 100%; text-align: center;">
      <img src="assets/img/camera.png" width="70px" (click)="deviceChange(action)">
    </div>

  </div>
</div>


