<ng-container *ngIf="myItem">

<div class="single-portfolio-area pt-80">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                  <div class="single-portfolio-img zoom-hover mb-40" *ngIf="myItem.immagine">
                      <img src="{{myItem.immagine}}" alt="">
                  </div>
                    <div class="portfolio-decription-wrap">
                      <div class="row">
                        <div class="col-xl-9 col-lg-8 col-md-12">
                          <div class="single-port-title">
                              <h3>{{myItem.nome}}</h3>
                              <h4>{{myItem.terziere}}</h4>
                          </div>
                          <div class="portfolio-decription-wrap">
                            <div class="single-port-peragraph">
                                <p [innerHTML]="myItem.testo"></p>
                            </div>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 d-none-sidebar sidebar">
                          <div class="portfolio-client-info client-info-border col-sidebar btn-sidebar">
                            <div class="sidebar-widget">
                              <div class="sidebar-categori">
                                <ul>
                                  <li>
                                    <div class="single-shortcode-btn blue-button small-button mb-30">
                                      <a class="btn-hover" [routerLink]="['/' + mainService.language + '/22/' + traduzioni?.sezioni[22].titoloUrl]">{{traduzioni?.tornaVolti}}</a>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="myItem.altriVolti.length > 0">
                            <div class="single-port-content content-titolo-sidebar">
                              <div class="single-port-title titolo-sidebar">
                                <h5>{{traduzioni?.scopriAltriVolti}}</h5>
                              </div>
                            </div>
                            <div class="portfolio-client-info client-info-border col-sidebar">
                              <div class="sidebar-widget">
                                <div class="sidebar-categori mb-40">
                                  <ul>
                                    <li *ngFor="let item of myItem.altriVolti">
                                      <i class="ti-arrow-circle-right"></i><a [routerLink]="[item.routerLink]">{{item.nome}}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                    </div>
                  </div>
                </div>
                <div class="row mb-30" *ngIf="myItem.immaginiDettaglio.length > 0">
                  <div class="col-lg-4 col-md-4" *ngFor="let item of myItem.immaginiDettaglio">
                      <div class="single-portfolio-img zoom-hover mb-30">
                          <img src="{{item}}" alt="">
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
<app-bottom-media
  [myItem]="myItem"
></app-bottom-media>

</ng-container>
