import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReservedAccessService } from './modules/reserved-access/reserved-access.service';
import { MainServiceService } from './services/main-service.service';
import { NotificationsService } from './services/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'museomultimedialemedievale-it';

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    public mainService: MainServiceService,
    private reservedAccessService: ReservedAccessService
  ) { }

  ngOnInit(): void {
    this.mainService.getConfiguration()
    .subscribe(
      data => {
        this.mainService.setConfigurationSubject(data);
      }
    );

    this.router.events
    .subscribe(
      event => {
        if(event instanceof NavigationEnd) {
          this.mainService.updateUrlParams(event);
          this.mainService.closeMenu();

          if (this.mainService.urlArray && (this.mainService.urlArray.length > 1)) {
            switch (this.mainService.urlArray[1]) {
              case 'confermaPassword':
                if (this.mainService.urlArray.length !== 4) {
                  // tslint:disable-next-line: max-line-length
                  this.notificationsService.show('error', '', 'Il link utilizzato per questa richiesta non è più valido.<br>Ripetere nuovamente la procedura');
                } else {
                  this.confermaPassword(Number(this.mainService.urlArray[2]), this.mainService.urlArray[3]);
                }

                break;
            }
          }
        }
      }
    );
  }

  confermaPassword(idUser: number, token: string) {
    this.reservedAccessService.confermaPassword(idUser, token)
    .pipe(first())
    .subscribe(
      data => {
        // tslint:disable-next-line: max-line-length
        this.notificationsService.show('success', 'Attivazione riuscita', 'Puoi effettuare il login con le credenziali indicate nella mail ricevuta');
        this.router.navigate(["/"]);
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.router.navigate(["/"]);
      }
    );
  }

  /* private loadGoogleAnalytics(trackingID: string): void {
    let gaScript = document.createElement("script");
    gaScript.setAttribute("async", "true");
    gaScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + trackingID);

    let gaScript2 = document.createElement("script");
    gaScript2.innerText = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" + trackingID + "');";

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  } */

}



