import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseoMultimedialeComponent } from './museo-multimediale.component';



@NgModule({
  declarations: [
    MuseoMultimedialeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MuseoMultimedialeComponent
  ]
})
export class MuseoMultimedialeModule { }
