<ng-container *ngIf="myItem && traduzioni">

<div class="about-area pt-120 pb-120 pagina-volti">
  <div class="container">
    <div class="row d-flex align-items-center" *ngIf="myItem.immagine_vincitori">
      <div class="col-lg-6 col-md-12">
        <div class="about-us-wrap mr-20">
          <img src="{{myItem.immagine_vincitori}}" alt="">
          <div class="works-video" *ngIf="myItem.video_vincitori">
            <a class="video-btn video-popup" (click)="openModalVideo(true)">
              <i class="ti-control-play"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="tools-content ml-20 text-center">
          <h2>{{traduzioni.titoloVincitoriUltimaCorsa}}</h2>
          <p [innerHTML]="myItem.testo_vincitori"></p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-slider-volti></app-slider-volti>

<div class="shortcode-progress-bars-area pt-120 pb-120">
  <div class="container">
    <div class="section-title text-center mb-50">
      <h2>{{traduzioni.vincitori}}</h2>
      <p [innerHTML]="myItem.testo_vittorie"></p>

    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="skill-bar">
          <div class="skill-bar-item">
            <span>Fraporta</span>
            <div class="progress">
              <div class="progress-bar wow fadeInLeft" [ngStyle]="{'width':myItem.percent_fraporta+'%'}" data-wow-duration="1.5s" data-wow-delay="1.2s">
                <span class="text-top">{{myItem.vittorie_fraporta}} {{traduzioni.vittorie}}</span>
              </div>
            </div>
          </div>
          <div class="skill-bar-item">
            <span>Mezule</span>
            <div class="progress">
              <div class="progress-bar wow fadeInLeft" [ngStyle]="{'width':myItem.percent_mezule+'%'}" data-wow-duration="1.5s" data-wow-delay="1.2s">
                <span class="text-top">{{myItem.vittorie_mezule}} {{traduzioni.vittorie}}</span>
              </div>
            </div>
          </div>
          <div class="skill-bar-item">
            <span>Santa Maria</span>
            <div class="progress">
              <div class="progress-bar wow fadeInLeft" [ngStyle]="{'width':myItem.percent_santamaria+'%'}"  data-wow-duration="1.5s" data-wow-delay="1.2s">
                <span class="text-top">{{myItem.vittorie_santamaria}} {{traduzioni.vittorie}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="myItem?.vincitori.length > 0">
      <div class="section-title text-center mb-50 mt-70">
        <p>
        <ng-container *ngFor="let item of myItem.vincitori">
          <div class="mb-30">
            <div>
              <b><span class="capitalize">{{traduzioni.edizione}}</span> {{item.edizione}}</b>
            </div>
            <div *ngIf="item.vincitore != ''">
              <span class="capitalize">{{traduzioni.vincitoreCorsa}}:</span> {{item.vincitore}}
            </div>
            <div *ngIf="item.migliorCavaliere != ''">
              <span class="capitalize">{{traduzioni.migliorCavaliere}}:</span> {{item.migliorCavaliere}}
            </div>
          </div>
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</div>

<div #modal id="modalVideo" class="modal" style="height: 100%; z-index: 999999" [ngStyle]="{'display':showVideo === true ? 'block' : 'none' }" *ngIf="myItem.video_vincitori">

  <!-- Modal content -->
  <div #modalContent class="modal-content" style="height: 100%;">
      <div style="height: 4%; padding-top: 5px; width: 100%; text-align: center;">
        <button (click)="openModalVideo(false)">{{traduzioni.chiudi}}</button>
      </div>
      <div style="height: 93%;" [innerHTML]="videoUrl"></div>
  </div>

</div>

</ng-container>
