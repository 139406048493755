<div *ngIf="traduzioni">
    <app-login *ngIf="step == 'login'" (onSuccess)="afterLoginSuccess()"></app-login>
    <app-recover *ngIf="step == 'recover'" (onSuccess)="login()"></app-recover>
    <app-register *ngIf="step == 'register'" (onSuccess)="login()"></app-register>
    <app-logout (onLogout)="logout()" *ngIf="step == 'logout'" ></app-logout>

    <div class="row" *ngIf="step != 'logout'">
      <div class="btn-login">
        <a (click)="registrati()" class="pointer"> <h6 class="" *ngIf="(step == 'login') || (step == 'recover')" > {{traduzioni.nonSeiRegistrato}} <span>{{traduzioni.registrati}}</span></h6></a>
        <a (click)="login()" class="pointer"> <h6 class="" *ngIf="(step == 'register') || (step == 'recover')"  > {{traduzioni.seiRegistrato}} <span>{{traduzioni.effettuaLogin}}</span></h6></a>
        <a (click)="recupera()" class="pointer"><h6 class="" *ngIf="(step == 'login') || (step == 'register')">{{traduzioni.passwordDimenticata}}? </h6></a>
      </div>
    </div>

</div>
