import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MainServiceService } from 'src/app/services/main-service.service';
import { VoltiDellaCorsaService } from '../volti-della-corsa.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-slider-volti',
  templateUrl: './slider-volti.component.html',
  styleUrls: ['./slider-volti.component.css']
})
export class SliderVoltiComponent implements OnInit {
  myItem: any;
  traduzioni: any;

  customOptions: OwlOptions = {
    loop: true,
    margin: 30,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      },
      1200: {
        items: 5
      },
      1680: {
        items: 6
      }
    }
  }

  constructor(
    private mainService: MainServiceService,
    private service: VoltiDellaCorsaService

  ) { }

  ngOnInit(): void {
    forkJoin([
      this.service.getElencoVolti(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;

        for(let i=0; i < item.volti.length; i++) {
          item.volti[i].routerLink = '/' + this.mainService.language + '/22/' + this.traduzioni.sezioni[22].titoloUrl + '/' + item.volti[i].id + '/' + item.volti[i].titoloUrl;
        }

        this.myItem = item;
        this.mainService.showLoader(false);
     });
  }

}
