
<div class="cart-area pt-95 pb-100 check-opere">
  <div class="container">
    <app-reserved-access (onChangeStatus)="onChangeStatus()"></app-reserved-access>
    <div *ngIf="userLoggedIn && traduzioni">
      <div class="testoBase">
        {{traduzioni.haiRispostoA}} <b>{{statoGioco?.risposteDate}} {{domandae}}</b> su {{statoGioco?.totDomande}}
        {{traduzioni.totalizzando}} <b>{{statoGioco?.risposteEsatte}} {{traduzioni.puntoi}}</b>

        <div class="row pt-20 pb-40">
          <div class="single-shortcode-btn black-button medium-button mb-30">
            <a class="btn-hover" [routerLink]="['/' + mainService.language + '/26/' + traduzioni.sezioni[26].titoloUrl]">
              {{traduzioni.scopriComeSiGioca}}
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <form action="#">
            <div class="table-content table-responsive cart-table">
              <table>
                <thead>
                  <tr>
                    <th class="cart-product">{{traduzioni.elencoArgomenti}}</th>
                    <th class="cart-price"> {{traduzioni.haiRisposto}}?  </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of arrayItems" (click)="goToDetail(item)" class="pointer">
                    <td class="cart-img-name">
                      <div class="cart-img" style="text-align: center;"><img [src]="item.immagine" alt=""></div>
                      <div class="cart-name">{{item.titolo}}<br><small>{{item.titoloSala}}</small></div>
                    </td>
                    <td class="cart-price">
                      <div class="cart-img" [ngSwitch]="item.risposto">
                        <img *ngSwitchCase="'0'" src="assets/img/rispostoKO.png" alt="">
                        <img *ngSwitchCase="'1'" src="assets/img/rispostoOK.png" alt="">
                        <img *ngSwitchDefault src="assets/img/rispostoNO.png" alt="">
                      </div>
                      <div>
                        {{traduzioni.vaiScheda}}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
