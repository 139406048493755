import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReservedAccessService } from '../reserved-access.service';
import { first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';

declare var require: any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  user: any;
  traduzioni: any;
  @Output() public onLogout = new EventEmitter();

  constructor(
    private service: ReservedAccessService,
    private mainService: MainServiceService,
  ) { }

  ngOnInit(): void {
    this.service.getUserBasicDataSubject().pipe(first(data => data != null)).subscribe(data => {
      this.user = data;
    });
    this.mainService.getTraductionsSubject().pipe(first(data => data != null)).subscribe(data => {
      this.traduzioni = data;
    });
  }

  logout() {
    const Swal = require('sweetalert2');

    Swal.fire({
      title: 'Confermi di voler veramente effettuare il logout?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      buttonsStyling: true
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.onLogout.emit();
      }
    });

  }
}
