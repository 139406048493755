import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IlMedioevoComponent } from './il-medioevo.component';



@NgModule({
  declarations: [IlMedioevoComponent],
  imports: [
    CommonModule
  ]
})
export class IlMedioevoModule { }
