import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { SaleService } from '../sale.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-argomento-sala',
  templateUrl: './argomento-sala.component.html',
  styleUrls: ['./argomento-sala.component.css']
})
export class ArgomentoSalaComponent implements OnInit {
  myItem: any;
  traduzioni: any;

  constructor(
    private service: SaleService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getArgomento(this.mainService.argumentId, this.mainService.detailId),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;
        if (item.argomenti.length > 0) {
          for(let i=0; i < item.argomenti.length; i++) {
            item.argomenti[i].routerLink = '/' + this.mainService.language + '/10/' + this.traduzioni.sezioni[10].titoloUrl + '/' + item.id_sala + '/' + item.titoloUrl_sala + '/' + item.argomenti[i].id + '/' + item.argomenti[i].titoloUrl;
          }
        }

        item['selfUrl'] = '/' + this.mainService.language + '/10/' + this.traduzioni.sezioni[10].titoloUrl + '/' + item.id_sala + '/' + item.titoloUrl_sala + '/' + item.id + '/' + item.titoloUrl;

        this.myItem = item;
        this.mainService.showLoader(false);
      });
  }


}
