<div class="single-portfolio-area pt-80 pb-60" *ngIf="myItem?.length > 0">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                  <div *ngFor="let item of myItem">
                    <div class="single-portfolio-img zoom-hover">
                        <img src="{{item.immagine}}" alt="">
                    </div>
                    <div class="single-port-content">
                        <div class="single-port-title">
                            <h3>{{item.titolo}}</h3>
                        </div>
                    </div>
                    <div class="portfolio-decription-wrap">
                        <div class="row">
                            <div class="col-12">
                                <div class="single-port-peragraph">
                                    <p [innerHTML]="item.testo"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
