import { Component, OnInit, Input } from '@angular/core';
import { ReservedAccessService } from 'src/app/modules/reserved-access/reserved-access.service';

@Component({
  selector: 'app-gioca',
  templateUrl: './gioca.component.html',
  styleUrls: ['./gioca.component.css']
})
export class GiocaComponent implements OnInit {
  userLoggedIn: boolean;
  @Input() qrcodePass: boolean = true; // bypass qrcode
  @Input() myItem: any;

  constructor(
    public reservedAccessService: ReservedAccessService,
  ) {
    this.checkUserLogged();
  }

  checkUserLogged() {
    this.userLoggedIn = this.reservedAccessService.isUserLoggedIn();
  }

  ngOnInit(): void {
  }

  onChangeStatus() {
    this.checkUserLogged();
  }
}
