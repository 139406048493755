<div class="portfolio-area pt-75 pb-80 container-padding-res">
  <div class="container">
      <div class="portfolio-style pro-col-40">
          <div class="row grid">
              <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 grid-item" *ngFor="let item of arrayItems">
                  <div class="portfolio-wrap mb-32">
                      <div class="portfolio-img">
                        <a class="img-popup" (click)="openGallery(item.id)" >
                          <img alt="" src="{{item.immagine}}">
                        </a>
                      </div>
                      <div class="portfolio-content">
                          <div class="portfolio-title">
                              <h4>{{item.titolo}}</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ngx-image-gallery 
[images]="images" 
[conf]="conf"
></ngx-image-gallery>
