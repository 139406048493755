<header class="header-area transparent-bar sticky-bar header-padding header-hm6" [ngClass]="{'stick': sticky === true}">
  <div class="container-fluid">
      <div class="header-wrap header-flex">
          <div class="logo mt-10">
              <a [routerLink]="['/']">
                  <img class="logo-normally-none" alt="" src="assets/img/logo-W.png">
                  <img class="logo-sticky-none" alt="" src="assets/img/logo.png">
              </a>
          </div>
          <div class="main-menu" *ngIf="mainService.traductions?.menuPrincipale.length">
            <nav>
              <ul class="top-menu">
                <li *ngIf="configuration?.social.length > 0">
                  <ul class="color-b">
                    <li *ngFor="let social of configuration.social">
                      <a href="{{social.url}}" target="_blank" ><i class="ti-{{social.iconAlt}}"></i></a>
                    </li>
                  </ul>
                </li>
                <li>
                  <i class="ti-world"></i>
                  <ul class="submenu">
                    <li><a [routerLink]="langUrl?.eng">ENG</a></li>
                    <li><a [routerLink]="langUrl?.ita">ITA</a></li>
                  </ul>
                </li>
                <li *ngIf="0">
                  <a href="#"><i class="ti-user"></i></a>
                </li>
              </ul>
              <ul>
                <li *ngFor="let item of mainService.traductions.menuPrincipale">

                  <a *ngIf="!item.sub; else subSection;" [routerLink]="['/' + mainService.language + '/' + item.id + '/' + item.titoloUrl]" routerLinkActive="active">{{item.denominazione}}</a>
                  <ng-template #subSection>
                    {{item.denominazione}}
                    <ul class="submenu">
                      <li *ngFor="let subItem of item.sub">
                        <a [routerLink]="['/' + mainService.language + '/' + subItem.id + '/' + subItem.titoloUrl]" routerLinkActive="active">{{subItem.denominazione}}</a>
                      </li>
                    </ul>
                  </ng-template>

                </li>
              </ul>
            </nav>
          </div>
          <div class="header-right-wrap mt-55">

          </div>
      </div>
  </div>
  <div class="slicknav_menu">
    <a aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style="outline: none;">
      <span class="slicknav_menutxt">
        <i class="menu-icon-open ti-menu" (click)="openMenu()"></i>
        <i class="menu-icon-colse  ti-close" (click)="closeMenu()"></i>
      </span>
      <span class="slicknav_icon">
        <span class="slicknav_icon-bar"></span>
        <span class="slicknav_icon-bar"></span>
        <span class="slicknav_icon-bar"></span>
      </span>
    </a>
    <nav class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" style="display: none;">
      <ul class="menu-overflow">
        <ng-container *ngFor="let item of mainService.traductions?.menuPrincipale">
          <li [ngClass]="{'slicknav_collapsed slicknav_parent': item.sub?.length > 0}" id="menu_{{item.id}}">
            <a *ngIf="!item.sub; else subSection;" [routerLink]="['/' + mainService.language + '/' + item.id + '/' + item.titoloUrl]" routerLinkActive="active" role="menuitem">{{item.denominazione}}</a>
            <ng-template #subSection>
              <a (click)="toggleSubmenu(item.id)" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row" style="outline: none;">
                {{item.denominazione}} <span class="slicknav_arrow"><i class="ti-plus"></i></span>
              </a>
              <ul class="submenu slicknav_hidden" role="menu" aria-hidden="true" style="display: none;">
                <li *ngFor="let subItem of item.sub" >
                  <a [routerLink]="['/' + mainService.language + '/' + subItem.id + '/' + subItem.titoloUrl]" routerLinkActive="active" role="menuitem" tabindex="-1">{{subItem.denominazione}}</a>
                </li>
              </ul>
            </ng-template>

          </li>
        </ng-container>

        <li class="slicknav_collapsed slicknav_parent" id="menu_lang">
          <a (click)="toggleSubmenu('lang')" role="menuitem" aria-haspopup="true" tabindex="-1" class="slicknav_item slicknav_row" style="outline: none;">
            <i class="ti-world"></i>
            <span class="slicknav_arrow"><i class="ti-plus"></i></span>
          </a>
          <ul class="submenu slicknav_hidden" role="menu" aria-hidden="true" style="display: none;">
            <li><a [routerLink]="langUrl?.eng" role="menuitem" tabindex="-1">ENG</a></li>
            <li><a [routerLink]="langUrl?.ita" role="menuitem" tabindex="-1">ITA</a></li>
          </ul>
        </li>
        <li *ngIf="0">
          <a href="#"><i class="ti-user"></i></a>
        </li>


    </ul>
</nav></div>
</header>
