<div class="container pt-50">
<app-reserved-access (onChangeStatus)="onChangeStatus()"></app-reserved-access>

<div *ngIf="userLoggedIn">
  <div *ngIf="!qrcodePass; else domanda">
    <div class="row pad-bot-70 align-center qrcode">
      <div class="col-12 textDiv pb-20">Cerca ed inquadra il qrcode presente nel pannello</div>
      <app-scan
        [item]="item"
        (code)="getQrCode($event)"
        style="width: 100%">
      </app-scan>
    </div>
  </div>
  <ng-template #domanda>
    <app-domanda
    [myItem]="myItem"
    ></app-domanda>
  </ng-template>


</div>

</div>
