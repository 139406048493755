<div class="portfolio-area pt-75 pb-80 container-padding-res area-elenco" *ngIf="arrayItems?.length > 0">
  <div class="container">
      <div class="portfolio-style pro-col-40">
          <div class="row grid">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 grid-item" *ngFor="let item of arrayItems">
              <div class="portfolio-wrap mb-32">
                <div class="portfolio-img">
                    <a class="" [routerLink]="[item.routerLink]"><img alt="" src="{{item.immagine}}"></a>
                </div>
                <div class="portfolio-content">
                  <div class="portfolio-title">
                    <h4><a [routerLink]="[item.routerLink]">{{item.titolo}}</a></h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 grid-item">
              <div class="portfolio-wrap mb-32">
                <div class="portfolio-img">
                  <a class="" [routerLink]="['/' + mainService.language + '/24/' + traduzioni?.sezioni[24].titoloUrl]"><img alt="" src="assets/img/foto-12-B.jpg"></a>
                </div>
                <div class="portfolio-content">
                  <div class="portfolio-title">
                    <h4><a [routerLink]="['/' + mainService.language + '/24/' + traduzioni?.sezioni[24].titoloUrl]">{{traduzioni?.sezioni[24].denominazione}}</a></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
