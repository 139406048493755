import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainServiceService } from 'src/app/services/main-service.service';

@Injectable({
  providedIn: 'root'
})
export class MGameService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  getElencoArgomenti(): Observable<any[]> {
    const url = this.mainService.SERVER_URL + `&action=getElencoArgomenti&lang=` + this.mainService.language;
    return this.http.get<any []>(url);
  }

  getStatoGioco(): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getStatoGioco`;
    return this.http.get<any>(url)
  }

  getDomanda(id: number, idSala: number): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getDomanda&lang=` + this.mainService.language + `&id=` + id + `&idSala=` + idSala;
    return this.http.get<any>(url)
  }

  sendRisposta(id: number, idSala: number, risposta: number) {
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=sendRisposta' , {id, idSala, risposta});
  }


}
