import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-cappello-volti',
  templateUrl: './cappello-volti.component.html',
  styleUrls: ['./cappello-volti.component.css']
})
export class CappelloVoltiComponent implements OnInit {
  myItem: any;
  traduzioni: any;
  showVideo: boolean = false;
  videoUrl: string = '';


  constructor(
    private router: Router,
    private mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.mainService.getContent(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;

        if (item.video_vincitori != '') {
          item.videoUrl = this.mainService.getVideoUrl(item.video_vincitori);
        }

        this.myItem = item;
        this.mainService.showLoader(false);
     });
  }

  openModalVideo(open: boolean): void {
    if (open) {
      this.videoUrl = this.myItem.videoUrl;
      this.showVideo = true;
    } else {
      this.videoUrl = '';
      this.showVideo = false;
    }
  }
}
