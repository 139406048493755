import { Component, OnInit } from '@angular/core';
import { PaginaCortesiaService } from './pagina-cortesia.service';
import { MainServiceService } from 'src/app/services/main-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagina-cortesia',
  templateUrl: './pagina-cortesia.component.html',
  styleUrls: ['./pagina-cortesia.component.css']
})
export class PaginaCortesiaComponent implements OnInit {
  item: any;

  constructor(
    private router: Router,
    private service: PaginaCortesiaService,
    private mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.service.getContenuto()
    .subscribe(item => {
      this.item = item;
      this.mainService.showLoader(false);
    })
  }

}
