import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';
import { ReservedAccessService } from './reserved-access.service';


@Component({
  selector: 'app-reserved-access',
  templateUrl: './reserved-access.component.html',
  styleUrls: ['./reserved-access.component.css']
})
export class ReservedAccessComponent implements OnInit {
  step: string;
  traduzioni: any;

  @Output() public onChangeStatus = new EventEmitter();

  constructor(
    public mainService: MainServiceService,
    private reservedAccessService: ReservedAccessService,
  ) {
    if (this.reservedAccessService.isUserLoggedIn()) {
      this.step = "logout"
      this.reservedAccessService.getUserBasicData();
    }
    else {
      this.step = "login"
    }

  }

  ngOnInit(): void {
    forkJoin([
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([traduzioni]) => {
        this.traduzioni = traduzioni;
    });
  }


  registrati() {
    this.step = 'register';
  }

  recupera() {
    this.step = 'recover';
  }

  login() {
    this.step = 'login';
  }

  logout() {
    this.onChangeStatus.emit();
    this.step = 'login';
  }

  afterLoginSuccess() {
    this.step = 'logout';
    this.onChangeStatus.emit();
  }
}
