import { Component, OnInit } from '@angular/core';
import { MainServiceService } from 'src/app/services/main-service.service';
import { VoltiDellaCorsaService } from '../volti-della-corsa.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-dettaglio-volti',
  templateUrl: './dettaglio-volti.component.html',
  styleUrls: ['./dettaglio-volti.component.css']
})
export class DettaglioVoltiComponent implements OnInit {
  myItem: any;
  traduzioni: any;

  constructor(
    private service: VoltiDellaCorsaService,
    private router: Router,
    public mainService: MainServiceService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getDettaglio(this.mainService.detailId),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([item, traduzioni]) => {
        this.traduzioni = traduzioni;

        if (item.altriVolti.length > 0) {
          for(let i=0; i < item.altriVolti.length; i++) {
            item.altriVolti[i].routerLink = '/' + this.mainService.language + '/22/' + this.traduzioni.sezioni[22].titoloUrl + '/' + item.altriVolti[i].id + '/' + item.altriVolti[i].titoloUrl;
          }
        }

        item['selfUrl'] = '/' + this.mainService.language + '/22/' + this.traduzioni.sezioni[22].titoloUrl + '/' + item.id + '/' + item.titoloUrl;

        this.myItem = item;
        this.mainService.showLoader(false);
      });
  }

}
