import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";
@Component({
  selector: 'app-bottom-media',
  templateUrl: './bottom-media.component.html',
  styleUrls: ['./bottom-media.component.css']
})
export class BottomMediaComponent implements OnInit {
  mostraGioco: boolean = false;
  modalReference: any;
  audioPlay: boolean = false;
  duration: string = '00:00';
  currentTime: string = '00:00';
  progress: number = 0

  @Input() myItem: any;
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  @ViewChild('modal_audioGallery') modal_audioGallery: any;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;

  conf: GALLERY_CONF = {
        imageOffset: '0px',
        showDeleteControl: false,
        showImageTitle: false,
      };

    images: GALLERY_IMAGE[] = [];

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    if (this.myItem.gallery.length > 0) {
      for(let i=0; i < this.myItem.gallery.length; i++) {
        this.images.push({url: this.myItem.gallery[i]});
      }
    }
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  showModalAudio(show: boolean): void {
    if (show) {
      this.modalReference = this.modalService.open(this.modal_audioGallery, { size: 'lg', windowClass: 'modal-holder', centered: true, backdrop: 'static'});
    }
    else {
      this.modalReference.close();
    }
  }

  gioca() {
    this.mostraGioco = true;
  }
}
