import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginaCortesiaComponent } from './pagina-cortesia.component';



@NgModule({
  declarations: [PaginaCortesiaComponent],
  imports: [
    CommonModule
  ]
})
export class PaginaCortesiaModule { }
