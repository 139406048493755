import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainServiceService } from 'src/app/services/main-service.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReservedAccessService {
  userBasicDataObject: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) {
    this.userBasicDataObject = new BehaviorSubject<any>(null);
  }


  isUserLoggedIn() {
    return !!this.getUserToken();
  }

  signUp(formValue: any[]) {
    const body = formValue;
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=signUp' , body);
  }

  recover(email: string) {
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=recover' , {email});
  }

  confermaPassword(idUser: number, token: string) {
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=attivaPassword' , {idUser, token});
  }

  login(email: string, password: string) {
    return this.http.post<any>(this.mainService.SERVER_URL + '&action=signIn' , {email, password});
  }

  saveUserToken(token: string) {
    localStorage.setItem('token', token);
  }

  getUserToken(): string {
    return localStorage.getItem('token');
  }


  setUserBasicData(userJson: any): void {
    localStorage.setItem('user', JSON.stringify(userJson));

    this.userBasicDataObject.next(userJson);
  }

  getUserBasicData(): void {
    const user = localStorage.getItem('user');
    if (user) {
      const userJson = JSON.parse(user);
      this.userBasicDataObject.next(userJson);
    }
  }

  getUserBasicDataSubject(): Observable<any> {
    return this.userBasicDataObject.asObservable();
  }

}
