import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MainServiceService } from 'src/app/services/main-service.service';
import { first } from 'rxjs/operators';
import { MGameService } from '../m-game.service';
import { ReservedAccessService } from 'src/app/modules/reserved-access/reserved-access.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-elenco-argomenti',
  templateUrl: './elenco-argomenti.component.html',
  styleUrls: ['./elenco-argomenti.component.css']
})
export class ElencoArgomentiComponent implements OnInit {
  arrayItems: any[];
  statoGioco: any;
  user: any;
  userLoggedIn: boolean;
  traduzioni: any;

  constructor(
    public mainService: MainServiceService,
    private reservedAccessService: ReservedAccessService,
    private service: MGameService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }


  getData() {
    this.userLoggedIn = this.reservedAccessService.isUserLoggedIn();

    if (this.userLoggedIn) {
      forkJoin([
        this.service.getElencoArgomenti(),
        this.service.getStatoGioco(),
        this.mainService.getTraductionsSubject().pipe(first(data => data != null))
      ])
      .subscribe(
        ([arrayItems, statoGioco, traduzioni]) => {
          this.traduzioni = traduzioni;
          if (arrayItems.length > 0) {
            for(let i=0; i < arrayItems.length; i++) {
              arrayItems[i].routerLink = '/' + this.mainService.language + '/10/' + this.traduzioni.sezioni[10].titoloUrl + '/' + arrayItems[i].idSala + '/' + arrayItems[i].titoloSalaUrl + '/' + arrayItems[i].id + '/' + arrayItems[i].titoloUrl;
            }
          }

          this.arrayItems = arrayItems;
          this.statoGioco = statoGioco;
          this.mainService.showLoader(false);
      });
    }
    else {
      this.mainService.showLoader(false);
    }
  }

  onChangeStatus() {
    this.getData();
  }


  goToDetail(item) {
    this.router.navigate([item.routerLink])
  }

}
