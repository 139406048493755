import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginaGenericaComponent } from './pagina-generica.component';



@NgModule({
  declarations: [
    PaginaGenericaComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PaginaGenericaComponent
  ]
})
export class PaginaGenericaModule { }
