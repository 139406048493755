import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomMediaComponent } from './bottom-media.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { MGameModule } from 'src/app/pages/m-game/m-game.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [BottomMediaComponent],
  imports: [
    CommonModule,
    NgxImageGalleryModule,
    MGameModule,
    RouterModule,
  ],
  exports: [
    BottomMediaComponent
  ]
})
export class BottomMediaModule { }
