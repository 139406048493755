<div class="row buttonLogout">
  <div class="col-12">
    <div style="float: right">
      <a (click)="logout()" class="pointer">
        <button class="single-shortcode-btn black-button white-button medium-button mb-30">
          Logout
        </button>
      </a>
    </div>
    <div class="nominativoAccesso">
      {{traduzioni.effettuatoAccessoCome}} <b>{{user.nome}} {{user.cognome}}</b>
    </div>
  </div>
</div>
