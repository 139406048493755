<div class="single-port-next-prev gray-bg-4 mt-50">
  <div class="container">
      <div class="port-next-prev-wrap">
          <div class="port-prev-btn">
            <ng-container *ngIf="myItem.idAlbum != 0; else noAlbum">
              <img class="pointer" alt="" src="assets/img/PHOTOgallery.png" (click)="openGallery()">
            </ng-container>
            <ng-template #noAlbum>
              <img alt="" src="assets/img/PHOTOgallery-gray.png">
            </ng-template>
          </div>
          <div class="single-port-bar port-center-btn">
            <ng-container *ngIf="myItem.audiogallery; else noAudio">
              <img class="pointer" alt="" src="assets/img/AUDIOgallery.png" (click)="showModalAudio(true)">
            </ng-container>
            <ng-template #noAudio>
              <img alt="" src="assets/img/AUDIOgallery-gray.png">
            </ng-template>
          </div>
          <div class="port-next-btn">
            <ng-container *ngIf="myItem.videogallery == 1; else noVideo">
              <a [routerLink]="[myItem.selfUrl + '/videogallery']"><img class="pointer" alt="" src="assets/img/VIDEOgallery.png"></a>
            </ng-container>
            <ng-template #noVideo>
              <img alt="" src="assets/img/VIDEOgallery-gray.png">
            </ng-template>
          </div>
          <div class="port-next-btn gioca-btn" *ngIf="myItem.gioca != -1">
            <ng-container *ngIf="myItem.gioca != 0; else noGioca">
              <a (click)="gioca()" class="pointer"><img alt="" src="assets/img/gioca.png"></a>
            </ng-container>
            <ng-template #noGioca>
              <img alt="" src="assets/img/gioca-gray.png">
            </ng-template>
          </div>
      </div>
  </div>
</div>

<ng-template #modal_audioGallery role="document" let-modal *ngIf="myItem.audiogallery">
  <div class="modal-header">
      <h5 class="modal-title mt-0">{{myItem.titolo}}</h5>
      <button type="button" class="btn-close" aria-hidden="true"
          (click)="showModalAudio(false)"></button>
  </div>
  <div class="modal-body audioModal">
    <audio controls autoplay>
      <source [src]="myItem.audiogallery" type="audio/mpeg">
    Your browser does not support the audio element.
    </audio>
  </div>
</ng-template>


<ngx-image-gallery 
[images]="images" 
[conf]="conf"
></ngx-image-gallery>

<app-gioca *ngIf="mostraGioco"
[myItem]="myItem"
></app-gioca>
