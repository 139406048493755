import { EventEmitter } from '@angular/core';
import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit, OnDestroy {
  actDevice: number = 0;
  numDevices: number = 0;
  camere : any;
  nuovaCamera: string;

  constructor(
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  public turnOn(action: any): void {
    console.log(action);
    console.log(action.devices);

    action.devices.subscribe(data => {
      this.camere = data;
      this.numDevices = data.length;
      console.log(data);
    });

    action['start']().subscribe();
  }

  public turnOff(action: any): void {
    action['stop']().subscribe();
  }

  public deviceChange(action: any): void {
    action.devices.pipe(
      take(1)).subscribe(data => {
      this.actDevice = (this.actDevice + 1) % this.numDevices;
      let selectedDevice = data[this.actDevice];

      this.nuovaCamera = selectedDevice;

      action['playDevice'](selectedDevice.deviceId).subscribe();
      console.log(selectedDevice.deviceId);

//      this.turnOn(action);
    });

  }
}
