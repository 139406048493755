<ng-container *ngIf="myForm">
  <div class="single-port-title pb-20">
    <h3>{{traduzioni.registrati}}</h3>
  </div>
  <div class="row pb-20">
    <div class="col-xl-8">
      <form>
        <div class="form-group pb-20" [formGroup]="myForm">
          <label for="">{{traduzioni.cognome}}</label>
          <input [class.is-invalid]="objInputCognome.invalid && objInputCognome.touched" type="text" class="form-control" formControlName="cognome" >
          <small *ngIf="((objInputCognome.invalid && objInputCognome.touched) || objInputCognome.dirty) && objInputCognome.errors?.required">
            Campo <strong>OBBLIGATORIO</strong>
          </small>
        </div>
        <div class="form-group pb-20" [formGroup]="myForm">
          <label for="">{{traduzioni.nome}}</label>
          <input [class.is-invalid]="objInputNome.invalid && objInputNome.touched" type="text" class="form-control" formControlName="nome" >
          <small *ngIf="((objInputNome.invalid && objInputNome.touched) || objInputNome.dirty) && objInputNome.errors?.required">
            Campo <strong>OBBLIGATORIO</strong>
          </small>
        </div>
        <div class="form-group pb-20" [formGroup]="myForm">
          <label for="">Email</label>
          <input [class.is-invalid]="objInputEmail.invalid && objInputEmail.touched"  type="email" class="form-control" formControlName="email" aria-describedby="">
          <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.required">
            Campo <strong>OBBLIGATORIO</strong>
          </small>
          <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.pattern">
              Valore <b>NON VALIDO</b>
          </small>
        </div>

        <div class="form-group pb-20" [formGroup]="myForm">
          <input type="checkbox" [class.checkbox-is-invalid]="objConsensoPrivacy.invalid && objConsensoPrivacy.touched" class="checkboxInput" id="consensoPrivacy" formControlName="consensoPrivacy">
          {{traduzioni.consensoRegistrazione}} <a [routerLink]="[privacyRouterlink]">{{privacyLink}}</a>
          <br><small *ngIf="((objConsensoPrivacy.invalid && objConsensoPrivacy.touched) || objConsensoPrivacy.dirty) && objConsensoPrivacy.errors?.required">
            Campo <strong>OBBLIGATORIO</strong>
          </small>

        </div>



      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-8">
      <div class="single-shortcode-btn black-button medium-button mb-30">
        <a class="btn-hover pointer" (click)="signUp()" [class.spinner]="loading" [disabled]="loading" >{{traduzioni.registrati}}</a>
      </div>
    </div>
  </div>
</ng-container>
