<div class="portfolio-area slider-corsa" *ngIf="myItem">
  <div class="container">
    <div class="section-title text-center mb-78">
      <h2>{{traduzioni.corsaSiamoNoi}}</h2>
      <p [innerHTML]="myItem.testo_volti"></p>
    </div>
  </div>
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of myItem.volti">
      <ng-template carouselSlide [id]="slide.id">
        <div class="portfolio-wrap portfolio-mrg">
          <div class="portfolio-img">
            <a class="" owlRouterLink="{{slide.routerLink}}" >
              <img [src]="slide.immagine" [title]="slide.nome+' '+slide.terziere" [alt]="slide.nome+' '+slide.terziere">
            </a>
          </div>
          <div class="portfolio-content">
            <div class="portfolio-title">
              <h4><a owlRouterLink="{{slide.routerLink}}" >{{slide.nome}}</a></h4>
                            <h6>{{slide.terziere}}</h6>
            </div>
          </div>
        </div>

      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
