<div class="single-portfolio-area pt-80" *ngIf="myItem">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                <div class="single-portfolio-img zoom-hover mb-40">
                    <img src="{{myItem.immagine}}" alt="">
                </div>

                <div class="portfolio-decription-wrap">
                    <div class="row">
                        <div class="col-xl-9 col-lg-8 col-md-12">
                            <div class="single-port-peragraph">
                              <p [innerHTML]="myItem.testo"></p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 d-none-sidebar sidebar">
                        <div class="portfolio-client-info client-info-border col-sidebar btn-sidebar">
                          <div class="sidebar-widget">
                            <div class="sidebar-categori">
                              <ul>
                                <li>
                                  <div class="single-shortcode-btn blue-button small-button mb-30">
                                    <a class="btn-hover" [routerLink]="['/' + mainService.language + '/10/' + traduzioni?.sezioni[10].titoloUrl]">{{traduzioni?.tornaSale}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="portfolio-client-info client-info-border col-sidebar">
                          <div class="sidebar-widget">
                            <div class="sidebar-categori mt-25" *ngIf="myItem?.keywords.length > 0">
                              <ul>
                                <li class="keywords" *ngFor="let item of myItem.keywords">{{item}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                </div>
                <div class="more-feature-area area-argomenti" *ngIf="myItem?.argomenti.length > 0">
                <div class="row g-0">
                  <div class="section-title">
                    <h3>{{traduzioni?.salaTroverai}}</h3>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of myItem.argomenti">
                    <div class="more-feature-wrap mb-30">
                      <a [routerLink]="[item.routerLink]">
                        <div class=" more-feature-margin">
                          <div class="more-feature-img">
                            <img src="{{item.immagine}}" alt="">
                          </div>
                          <div class="more-feature-content">
                            <div class="feature-title">
                              <h4>{{item.titolo}}</h4>
                            </div>
                            <div class="feature-icon">
                              <i class="ti-arrow-right hover-none"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
