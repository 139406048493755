<div class="single-portfolio-area pt-80 game" *ngIf="item">
  <div class="container">
    <div class="row pb-20">
      <div class="col-lg-4" *ngIf="item.immagine">
        <div class="single-portfolio-img zoom-hover mb-30">
          <img src="img/foto-4.jpg" alt="">
        </div>
      </div>
      <div [ngClass]="item.immagine ? 'col-lg-8' : 'col-lg-12'">
        <div class="single-portfolio-wrap">
          <div class="single-port-content">
            <div class="single-port-title">
              <h3>{{traduzioni.sezioni[26].denominazione}}?</h3>
            </div>
          </div>
          <div class="portfolio-decription-wrap">
            <div class="row">
              <div class="col-12">
                <div class="single-port-peragraph">
                  <p [innerHTML]="item.testo"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-20 pb-120">
      <div class="single-shortcode-btn black-button medium-button mb-30">
        <a class="btn-hover" [routerLink]="['/' + mainService.language + '/30/' + traduzioni.sezioni[30].titoloUrl]">
          {{traduzioni.sezioni[30].denominazione}}
        </a>
      </div>
    </div>
  </div>
</div>
