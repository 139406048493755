import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';
import { NotificationsService } from 'src/app/services/notification.service';
import { ReservedAccessService } from '../reserved-access.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  traduzioni: any;
  myForm: FormGroup;
  privacyLink: string = "";
  privacyRouterlink: string = "";
  loading = false;

  @Output() public onSuccess = new EventEmitter();

  constructor(
    private mainService: MainServiceService,
    private formBuilder: FormBuilder,
    private service: ReservedAccessService,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {
    forkJoin([
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([traduzioni]) => {
        this.traduzioni = traduzioni;
        this.privacyRouterlink = '/' + this.mainService.language + '/4/' + traduzioni.sezioni[4].titoloUrl;
        this.privacyLink = document.location.protocol + '//' + document.location.hostname + '/' + this.mainService.language + '/4/' + traduzioni.sezioni[4].titoloUrl;
        this.myForm = this.formBuilder.group({
          cognome: ['', [Validators.required]],
          nome: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.pattern(this.mainService.emailPattern)]],
          consensoPrivacy: ['', [Validators.required]],
       });
    });
  }

  get objInputEmail() {
    return this.myForm.get('email');
  }
  get objInputNome() {
    return this.myForm.get('nome');
  }
  get objInputCognome() {
    return this.myForm.get('cognome');
  }
  get objConsensoPrivacy() {
    return this.myForm.get('consensoPrivacy');
  }

  signUp() {
    if (this.loading) {
      return;
    }
    if (!this.myForm.valid) {
      this.mainService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.service.signUp(this.myForm.value)
    .pipe(first())
    .subscribe(
      data => {
        // tslint:disable-next-line: max-line-length
        this.notificationsService.show('success', 'Registrazione effettuata con successo', 'Riceverai all\'indirizzo email indicato le credenziali di accesso per accedere alla tua area riservata');
        this.loading = false;
        this.onSuccess.emit();
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.loading = false;
      }
    );
  }

}
