import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/services/main-service.service';

@Component({
  selector: 'app-pagina-generica',
  templateUrl: './pagina-generica.component.html',
  styleUrls: ['./pagina-generica.component.css']
})
export class PaginaGenericaComponent implements OnInit {
  item: any;

  constructor(
    private router: Router,
    private mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.mainService.getContent()
    .subscribe(item => {
      this.item = item;
      this.mainService.showLoader(false);
    });

  }

}
