import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page.component';
import { SliderComponent } from './slider/slider.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SliderMedioevoComponent } from './slider-medioevo/slider-medioevo.component';
import { VoltiDellaCorsaModule } from '../volti-della-corsa/volti-della-corsa.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [HomePageComponent, SliderComponent, SliderMedioevoComponent],
  imports: [
    CommonModule,
    CarouselModule,
    VoltiDellaCorsaModule,
    RouterModule
  ],
  exports: [
    HomePageComponent
  ]})
export class HomePageModule { }
