//import { ScultureGiocaService } from './../modules/sculture-gioca/sculture-gioca.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MainServiceService } from './main-service.service';
import { ReservedAccessService } from '../modules/reserved-access/reserved-access.service';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
    constructor(
      private mainService: MainServiceService,
      private reservedAccessService: ReservedAccessService
      ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url

        const jwt = this.reservedAccessService.getUserToken();

        const isApiUrl = request.url.startsWith(this.mainService.SERVER_URL);

        if (jwt && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            });
        }

        return next.handle(request);
    }
}
