<div class="shop-area pt-75 pb-80 gray-bg-4 container-padding-res shop-1" *ngIf="items">
  <div class="container">
      <div class="shop-area-wrapper pro-col-40">
          <div class="row grid">
              <div class="col-lg-6 col-md-6 item-hidden grid-item cat2 cat4" *ngFor="let item of items">
                  <div class="shop-wrap shop-shadow mb-40">
                      <div class="shop-img">
                          <a [routerLink]="item.routerLink">
                              <img src="{{item.immagine}}" alt="">
                          </a>
                      </div>
                      <div class="shop-content-2">
                          <div class="shop-name">
                              <h2>{{item.titolo}}</h2>
                              <p>{{item.testo}}</p>

                          </div>
                      </div>
                      <div class="shop-price">
                          <span class="shop-action-width"><a [routerLink]="item.routerLink">{{item.titoloLink}}</a></span>
                          <div class="shop-action-wrap shop-action-width">
                              <a [routerLink]="item.routerLink"><i class="ti-angle-right"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
