import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainServiceService } from 'src/app/services/main-service.service';
import * as $ from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class VoltiDellaCorsaService {

  constructor(
    private http: HttpClient,
    private mainService: MainServiceService
  ) { }

  getDettaglio(id: number): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getVolto&id=` + id + `&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  getElencoVolti(): Observable<any> {
    const url = this.mainService.SERVER_URL + `&action=getElencoVolti&lang=` + this.mainService.language;
    return this.http.get<any>(url)
  }

  getElencoVideo(id: number): Observable<any[]> {
    const url = this.mainService.SERVER_URL + `&action=getVideoVolto&id=` + id + `&lang=` + this.mainService.language;
    return this.http.get<any []>(url)
  }
}
