<div class="single-portfolio-area pt-80" *ngIf="myItem">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="single-portfolio-wrap">
                  <div class="single-portfolio-img zoom-hover mb-50">
                      <img src="{{myItem.immagine}}" alt="">
                  </div>
                  <div class="portfolio-decription-wrap">
                    <div class="row">
                      <div class="col-xl-9 col-lg-8 col-md-12">
                        <div class="portfolio-decription-wrap">
                            <div class="row">
                                <div class="col-12">
                                    <div class="single-port-peragraph">
                                      <p [innerHTML]="myItem.testo"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="blockquote-style-1 pl-150 pr-150 pb-60 pt-20" *ngIf="myItem.testo_citazione">
                          <div class="blockquote-style-wrap">
                            <blockquote class="blockquote-style2 text-center">
                              <img alt="" src="assets/img/blockquote.png">
                              <h4 [innerHTML]="myItem.testo_citazione"></h4>
                              <span>{{myItem.autore_citazione}}</span>
                            </blockquote>
                          </div>
                        </div>

                      </div>

                        <div class="col-xl-3 col-lg-4 d-none-sidebar sidebar">
                        <div class="portfolio-client-info client-info-border col-sidebar btn-sidebar">
                          <div class="sidebar-widget">
                            <div class="sidebar-categori">
                              <ul>
                                <li>
                                  <div class="single-shortcode-btn blue-button small-button mb-30">
                                    <a class="btn-hover" [routerLink]="['/' + mainService.language + '/10/' + traduzioni?.sezioni[10].titoloUrl]">{{traduzioni?.tornaSale}}</a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="single-port-content content-titolo-sidebar" *ngIf="myItem.argomenti.length > 0">
                          <div class="single-port-title titolo-sidebar">
                            <h5>{{traduzioni?.scopriAltriArgomenti}}</h5>
                          </div>
                        </div>

                        <div class="portfolio-client-info client-info-border col-sidebar">
                          <div class="sidebar-widget">
                            <div class="sidebar-categori mb-40" *ngIf="myItem.argomenti.length > 0">
                              <ul>
                                <li *ngFor="let item of myItem.argomenti">
                                  <i class="ti-arrow-circle-right"></i><a [routerLink]="[item.routerLink]">{{item.titolo}}</a>
                                </li>
                              </ul>
                            </div>
                            <div class="sidebar-categori mt-25" *ngIf="myItem?.keywords.length > 0">
                              <ul>
                                <li class="keywords" *ngFor="let item of myItem.keywords">{{item}}</li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="row mb-30" *ngIf="myItem.immaginiDettaglio.length > 0">
                      <div class="col-lg-4 col-md-4" *ngFor="let item of myItem.immaginiDettaglio">
                          <div class="single-portfolio-img zoom-hover mb-30">
                              <img src="{{item}}" alt="">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<app-bottom-media  *ngIf="myItem"
  [myItem]="myItem"
></app-bottom-media>
