import { Component, OnInit } from '@angular/core';
import { HomePageService } from './home-page.service';
import { MainServiceService } from 'src/app/services/main-service.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  public homePage: any;
  traduzioni: any;

  constructor(
    private service: HomePageService,
    private router: Router,
    public mainService: MainServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getHomePage(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([homePage, traduzioni]) => {
        this.traduzioni = traduzioni;

        for(let i=0; i < homePage.sale.length; i++) {
          homePage.sale[i].routerLink = '/' + this.mainService.language + '/10/' + this.traduzioni.sezioni[10].titoloUrl + '/' + homePage.sale[i].id + '/' + homePage.sale[i].titoloUrl;
        }

        if (homePage.multimedia.videoCode != '') {
          homePage.multimedia.videoUrl = this.mainService.sanitizeURL('https://www.youtube.com/embed/' + homePage.multimedia.videoCode);
        }

        this.homePage = homePage
        this.mainService.showLoader(false);
     });
  }

}
